import * as React from 'react';

const ArrowButtonRightIcon = (props) => (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_47940_7227)">
      <path
        d="M0.124434 4.10359L5.61643 10.3391C5.66618 10.391 5.72591 10.4323 5.79203 10.4605C5.85815 10.4887 5.9293 10.5032 6.00118 10.5032C6.07307 10.5032 6.14421 10.4887 6.21033 10.4605C6.27645 10.4323 6.33619 10.391 6.38593 10.3391L11.8754 4.10359C11.9193 4.05413 11.9529 3.99634 11.974 3.93367C11.9951 3.871 12.0034 3.80471 11.9984 3.73876C11.9935 3.67281 11.9753 3.60853 11.9449 3.54975C11.9146 3.49097 11.8728 3.43889 11.8219 3.39659L10.8969 2.61809C10.7938 2.53113 10.6606 2.48821 10.5261 2.4986C10.3916 2.50898 10.2665 2.57183 10.1779 2.67359L6.09543 7.40009C6.0837 7.41373 6.06916 7.42467 6.05281 7.43218C6.03645 7.43968 6.01868 7.44356 6.00068 7.44356C5.98269 7.44356 5.96491 7.43968 5.94856 7.43218C5.93221 7.42467 5.91767 7.41373 5.90593 7.40009L1.82193 2.67309C1.73328 2.5718 1.60821 2.50962 1.47393 2.50009C1.40736 2.49416 1.34027 2.50163 1.27663 2.52207C1.213 2.54251 1.1541 2.5755 1.10343 2.61909L0.177934 3.39659C0.126795 3.43881 0.0846792 3.4909 0.0540981 3.54974C0.0235169 3.60858 0.00509633 3.67298 -6.56126e-05 3.73909C-0.00507067 3.80511 0.00338348 3.87146 0.0247843 3.93412C0.0461852 3.99677 0.0800869 4.05443 0.124434 4.10359Z"
        fill="white"
        {...props}
      />
    </g>
    <defs>
      <clipPath id="clip0_47940_7227">
        <rect width="12" height="12" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default ArrowButtonRightIcon;
