const RightGrayIcon = (props) => (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_30621_739)">
      <path
        d="M12 6.48163C11.9996 6.3114 11.963 6.1432 11.8928 5.98809C11.8226 5.83299 11.7203 5.6945 11.5926 5.58174L5.99725 0.66285C5.85271 0.544669 5.66789 0.48715 5.48173 0.502415C5.29556 0.51768 5.1226 0.604537 4.9993 0.74468C4.876 0.884824 4.81198 1.06731 4.82074 1.25367C4.8295 1.44004 4.91035 1.61573 5.04626 1.74372L9.36926 5.54325C9.38822 5.56005 9.40162 5.5822 9.40769 5.60678C9.41377 5.63136 9.41223 5.6572 9.40328 5.68089C9.39432 5.70457 9.37838 5.72498 9.35756 5.73941C9.33674 5.75385 9.31202 5.76163 9.28667 5.76172H0.720751C0.529596 5.76172 0.34627 5.83757 0.211103 5.97258C0.075936 6.10759 0 6.2907 0 6.48163C0 6.67256 0.075936 6.85568 0.211103 6.99068C0.34627 7.12569 0.529596 7.20154 0.720751 7.20154H9.28517C9.31051 7.20179 9.33518 7.20967 9.35594 7.22417C9.37671 7.23867 9.3926 7.2591 9.40153 7.28278C9.41046 7.30647 9.41201 7.33229 9.40599 7.35687C9.39996 7.38145 9.38664 7.40364 9.36776 7.42052L5.04475 11.22C4.96915 11.281 4.90668 11.3567 4.86109 11.4424C4.81551 11.5281 4.78775 11.6222 4.77949 11.7189C4.77123 11.8156 4.78264 11.913 4.81304 12.0052C4.84343 12.0974 4.89218 12.1825 4.95635 12.2554C5.02052 12.3283 5.09879 12.3875 5.18645 12.4294C5.27411 12.4714 5.36935 12.4951 5.46645 12.4993C5.56354 12.5035 5.66048 12.4881 5.75144 12.4539C5.8424 12.4197 5.9255 12.3675 5.99575 12.3004L11.5921 7.38152C11.7199 7.26885 11.8223 7.13039 11.8927 6.97527C11.963 6.82015 11.9996 6.65191 12 6.48163Z"
        fill="#6B6B6B"
        {...props}
      />
    </g>
    <defs>
      <clipPath id="clip0_30621_739">
        <rect width="12" height="12" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default RightGrayIcon;
