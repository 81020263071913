import * as React from 'react';

const CarouselBackArrowIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_30203_30143)">
      <path
        d="M11.1959 0.165913L2.88194 7.48858C2.81275 7.55491 2.7577 7.63455 2.72009 7.72271C2.68247 7.81087 2.66309 7.90573 2.66309 8.00158C2.66309 8.09743 2.68247 8.19229 2.72009 8.28045C2.7577 8.36861 2.81275 8.44825 2.88194 8.51458L11.1959 15.8339C11.2619 15.8925 11.3389 15.9371 11.4225 15.9653C11.5061 15.9935 11.5944 16.0046 11.6824 15.9979C11.7703 15.9913 11.856 15.967 11.9344 15.9266C12.0128 15.8861 12.0822 15.8304 12.1386 15.7626L13.1766 14.5292C13.2926 14.3917 13.3498 14.2141 13.3359 14.0348C13.3221 13.8554 13.2383 13.6887 13.1026 13.5706L6.80061 8.12725C6.78242 8.1116 6.76783 8.09221 6.75783 8.07041C6.74783 8.04861 6.74265 8.0249 6.74265 8.00091C6.74265 7.97692 6.74783 7.95322 6.75783 7.93142C6.76783 7.90961 6.78242 7.89023 6.80061 7.87458L13.1006 2.42925C13.1682 2.37057 13.2235 2.29915 13.2634 2.21908C13.3033 2.139 13.3271 2.05184 13.3333 1.96258C13.3412 1.87381 13.3312 1.78436 13.304 1.69951C13.2767 1.61466 13.2327 1.53614 13.1746 1.46858L12.1386 0.237246C12.0823 0.16906 12.0129 0.112906 11.9344 0.0721308C11.856 0.0313559 11.7701 0.00679511 11.6819 -8.74834e-05C11.5939 -0.0067609 11.5054 0.0045113 11.4219 0.0330458C11.3384 0.0615802 11.2615 0.106783 11.1959 0.165913Z"
        fill="#000000ad"
        {...props}
      />
    </g>
    <defs>
      <clipPath id="clip0_30203_30143">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CarouselBackArrowIcon;
