const FileLandscape = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" {...props}>
      <path
        d="M14.9715 3.80467L11.3622 0.195333C11.2372 0.0703004 11.0676 3.77583e-05 10.8908 0H3.16683C2.81321 0 2.47407 0.140476 2.22402 0.390524C1.97397 0.640573 1.8335 0.979711 1.8335 1.33333V14.6667C1.8335 15.0203 1.97397 15.3594 2.22402 15.6095C2.47407 15.8595 2.81321 16 3.16683 16H13.8335C14.1871 16 14.5263 15.8595 14.7763 15.6095C15.0264 15.3594 15.1668 15.0203 15.1668 14.6667V4.276C15.1668 4.0992 15.0965 3.92966 14.9715 3.80467ZM13.8335 14.3333C13.8335 14.4217 13.7984 14.5065 13.7359 14.569C13.6734 14.6315 13.5886 14.6667 13.5002 14.6667H3.50016C3.41176 14.6667 3.32697 14.6315 3.26446 14.569C3.20195 14.5065 3.16683 14.4217 3.16683 14.3333V1.66667C3.16683 1.57826 3.20195 1.49348 3.26446 1.43096C3.32697 1.36845 3.41176 1.33333 3.50016 1.33333H10.4768C10.5651 1.33335 10.6497 1.36835 10.7122 1.43067L13.7362 4.45467C13.7985 4.51714 13.8335 4.60177 13.8335 4.69V14.3333Z"
        fill="white"
        {...props}
      />
      <path
        d="M6.36605 6.66667C7.10243 6.66667 7.69938 6.06971 7.69938 5.33333C7.69938 4.59695 7.10243 4 6.36605 4C5.62967 4 5.03271 4.59695 5.03271 5.33333C5.03271 6.06971 5.62967 6.66667 6.36605 6.66667Z"
        fill="white"
        {...props}
      />
      <path
        d="M9.98221 7.11867C9.95225 7.07073 9.91058 7.0312 9.86113 7.00379C9.81168 6.97639 9.75608 6.96201 9.69954 6.96201C9.64301 6.96201 9.5874 6.97639 9.53795 7.00379C9.4885 7.0312 9.44684 7.07073 9.41688 7.11867L7.79954 9.70667C7.78445 9.73049 7.76357 9.75011 7.73886 9.7637C7.71415 9.7773 7.68641 9.78442 7.65821 9.78442C7.63001 9.78442 7.60226 9.7773 7.57756 9.7637C7.55285 9.75011 7.53197 9.73049 7.51688 9.70667L6.94088 8.78534C6.91091 8.7374 6.86925 8.69787 6.8198 8.67046C6.77035 8.64305 6.71475 8.62867 6.65821 8.62867C6.60167 8.62867 6.54607 8.64305 6.49662 8.67046C6.44717 8.69787 6.40551 8.7374 6.37554 8.78534L4.52554 11.7447C4.50969 11.7699 4.50089 11.7989 4.50006 11.8287C4.49924 11.8585 4.50642 11.888 4.52085 11.9141C4.53528 11.9401 4.55644 11.9619 4.58212 11.977C4.60781 11.9921 4.63707 12 4.66688 12H12.7335C12.7633 12 12.7926 11.9921 12.8183 11.977C12.844 11.9619 12.8651 11.9401 12.8796 11.9141C12.894 11.888 12.9012 11.8585 12.9004 11.8287C12.8995 11.7989 12.8907 11.7699 12.8749 11.7447L9.98221 7.11867Z"
        fill="white"
        {...props}
      />
    </svg>
  );
};

export default FileLandscape;
