import * as React from 'react';

const CarouselNextArrowIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_30203_30144)">
      <path
        d="M4.80512 15.8339L13.1191 8.51122C13.1883 8.44489 13.2434 8.36525 13.281 8.27709C13.3186 8.18892 13.338 8.09407 13.338 7.99822C13.338 7.90237 13.3186 7.80751 13.281 7.71935C13.2434 7.63119 13.1883 7.55155 13.1191 7.48522L4.80512 0.165886C4.73916 0.107346 4.66212 0.0626614 4.57855 0.0344865C4.49499 0.00631153 4.40661 -0.0047783 4.31867 0.00187593C4.23074 0.00853015 4.14504 0.0327926 4.06667 0.0732217C3.98829 0.113651 3.91885 0.169421 3.86245 0.23722L2.82445 1.47055C2.7085 1.60808 2.65128 1.78569 2.66512 1.96504C2.67896 2.14439 2.76277 2.31111 2.89845 2.42922L9.20045 7.87255C9.21863 7.8882 9.23323 7.90759 9.24323 7.92939C9.25323 7.95119 9.25841 7.9749 9.25841 7.99889C9.25841 8.02287 9.25323 8.04658 9.24323 8.06838C9.23323 8.09019 9.21863 8.10957 9.20045 8.12522L2.90045 13.5706C2.8329 13.6292 2.77758 13.7006 2.73766 13.7807C2.69773 13.8608 2.67399 13.948 2.66778 14.0372C2.65987 14.126 2.66983 14.2154 2.69709 14.3003C2.72434 14.3851 2.76833 14.4637 2.82645 14.5312L3.86512 15.7652C3.92162 15.8329 3.99111 15.8885 4.06948 15.9288C4.14785 15.9691 4.2335 15.9933 4.32139 16C4.40927 16.0066 4.49759 15.9956 4.58114 15.9675C4.66468 15.9395 4.74175 15.8949 4.80778 15.8366L4.80512 15.8339Z"
        fill="#000000ad"
        {...props}
      />
    </g>
    <defs>
      <clipPath id="clip0_30203_30144">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CarouselNextArrowIcon;
